:root {
  --main-background-color: #F9F7F8;
  --contrast-bg: #ebece9;
  --header-font: 'Montserrat', sans-serif;
  --main-text-color: #23396A;
  --contrast-text-color: #0C0C4D;
  --hero-column-width: 50.50%;
  --btn-hover: #D9C2B5;
  --body-font: 'Montserrat', sans-serif;
  --border: 3px #AB706A solid;
  --fillament: #fcfdf9;
  --lightbulb: #FFE57B;
  --glow: #BD4F4F;
  --box-shadow-desktop: 10px 10px 25px #CBC1CF;
  --box-shadow-mobile: 7px 7px 20px #CBC1CF;
  --enlarge-font: 110%;
  --increase-font-weight: 600;
  }

  body{
    background-color: var(--main-background-color);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

p{
  font-family:  var(--body-font);
  line-height: 1.4em; 
  color: var(--main-text-color);
}

h1, h2, h3, h4, h5, h6{
  font-family:  var(--header-font);
  color: var(--main-text-color);
}

h2{
  font-weight: 300;
  font-size: 2.5em;
}

section, header, footer{
    height: fit-content;
}

.hidden{
  display: none;
}

.preimage-loading {
  opacity: 0;
}

.preimage-loaded {
  opacity: 1;
}

.image-loaded{
  display: none!important;
}

.LQ-img{
  transition: opacity .5s ease;
  filter: blur(5px);
}

a{
  text-decoration: none;
  color: var(--main-text-color);
}

a, button{
  transition: all 0.3s;
  cursor: pointer;
}

a:focus, button:focus{
  cursor: pointer;
  outline: none;
}

/*hover and focus*/

.git-link:hover, .git-link:focus{
  font-size: var(--enlarge-font);
}

.portfolio-link:focus{
  font-size: var(--enlarge-font);
  font-weight: var(--increase-font-weight);
  color: var(--btn-hover);
}

header a:hover, header a:focus, .side-bar a:hover, .side-bar a:focus, footer a:hover, footer a:focus{
  transform: scale(1.1);
  font-weight: var(--increase-font-weight);
}

.secondary-btn:hover, .secondary-btn button:focus{
  font-size: 1.1em;
}

.secondary-btn:hover, .secondary-btn:focus{
  background-color: var(--btn-hover);
  outline: none;
  cursor: pointer;
}

.submit-btn:hover, .portfolio-cta:hover, .submit-btn:focus, .portfolio-cta:focus, .git-cta:hover, .git-cta:focus{
  background-color: var(--btn-hover);
  font-size: var(--enlarge-font);
  outline: none;
  cursor: pointer;
}

.hero-cta:hover,  .hero-cta:focus{
  background-color: var(--btn-hover);
  font-size: var(--enlarge-font);
  outline: none;
  cursor: pointer;  
}

.hero-cta:hover, .portfolio-cta:hover, .hero-cta:focus, .portfolio-cta:focus{
  border-left: var(--border);
}

.git-cta:hover, .git-cta:focus{
  border-right: var(--border);
}

.submit-btn:hover, .submit-btn:focus{
  border-right: var(--border);
  border-bottom: none;
  border-left: none;
  cursor: pointer;
}

#fname:focus, #lname:focus, #email:focus, #message:focus{
  border: var(--border);
}

.project-git-wrapper a:hover, .project-git-wrapper a:focus{
  font-weight: var(--increase-font-weight);
  font-size: var(--enlarge-font);
}

.View-more__container-inner .portfolio-item:hover .portfolio-text, .View-more__container-inner .portfolio-item:focus .portfolio-text{
  height: 50%;
}

.portfolio-item:hover .portfolio-text h3{
  padding: 20px auto;
  margin: 0;
}

.portfolio-item:hover .revealOnHover, .portfolio-item:focus .revealOnHover {
  opacity: 1;
  display: block;
  transition: opacity 0.5s ease 0.1s;
}

.portfolio-item:hover .portfolio-text, .portfolio-item:focus .portfolio-text{
  height: 100%;
  opacity: 0.9;
}

@media screen and (min-width: 1024px){
  .portfolio-item:hover .portfolio-text h3, .portfolio-item:focus .portfolio-text h3{
    padding: 25px auto;
    margin: 0;
  }
}

.projects-git-link:hover{
  font-weight: var(--increase-font-weight);
  outline: none;
  }

/*buttons*/


button, .submit-btn, a{
  transition: all 0.3s,
}

.secondary-btn {
  padding: 25px 50px;
  margin-top: 50px;
  background-color: var(--contrast-bg);
  border: var(--border);
  box-sizing: border-box;
  color: var(--main-text-color);
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  font-size: 1em;
  font-weight: bold;
  font-family: var(--header-font);
}

@media screen and (min-width: 1100px) {
  .secondary-btn {
    width: 50%;
  }
}

/*Header*/

.header{
  display: flex;
  height: 60px;
  background-color: var(--main-background-color);
  justify-content: space-between;
  align-items: center;
}

header svg{
  margin: 0 10px;
  height:40px;
  width: 40px;
}

.dropdown{
  background: linear-gradient(180deg, var(--main-background-color), var(--contrast-bg));;
  position: absolute;
  width: 100vw;
  padding: 25px 0;
  z-index: 10;
  box-sizing: border-box;
}

.dropdownOpen{
  height: 40vh;
  height: (var(--vh) * 40);
  border-bottom: var(--border);
  transition: all .5s;
}

.dropdownClosed ul, .dropdownOpen ul{
  flex-direction: column;
  align-items: center;
  gap: 25px;
  overflow: hidden;
  visibility: hidden;
}

.dropdownOpen ul{
  height: 30vh;
  height: (var(--vh) * 30);
  visibility: visible;
  transition: height .5s ease, visibility 0.1s ease;
}

.dropdownClosed ul{
  height: 0;
  visibility: hidden;
  transition: height 0.5s ease, visibility .1s .4s;
}

.dropdownClosed {
  height: 0;
  margin: 0;
  padding: 0;
  border: 0px transparent solid;
  transition: height .5s, margin .5s, padding .5s, border .1s .4s;
}

.logo{
  margin: 0 10px;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 0.6rem;
  width: fit-content;
}

.logo p{
  font-family: var(--body-font);
  font-weight: bold;
  font-size: 1.5em;
}

header nav{
  display: none;
}

button.menu-icon {
  border: none;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 600px){

.header{
    display: grid;
    grid-template-columns: 50px 1fr 1fr 50px;
    justify-content: unset;
}

.logo{
  grid-column: 1;
  margin-left: 10px;
  font-size: 1em;
}

.logo p{
margin: 0;
font-size: 1em;
}

.menu-items{
  grid-column: 3/span 2;
  margin-right: 10px;
  z-index: 1;
  height: auto;
  display: block;
  color: var(--main-text-color);
}

header svg, .menu-icon{
  display: none!important;
}
}

@media screen and (min-width: 1024px) {

  .header{
    height: 50px;
  }

  .menu-items{
    margin-right: 0;
    grid-column: 3;
  }

  .logo{
    grid-column: 2;
    margin: 0;
  }
}

/*Hero Section*/

.hero{
  background-color: var(--main-background-color);
  height: calc(100vh - 60px);
  height: calc((var(--vh) * 100) - 60px);
  display: grid;
  grid-template-rows: calc(50vh - 105px) calc(50vh- 105px) 25px 75px 50px;
  grid-template-rows: calc(var(--vh) * 50 - 105px) calc(var(--vh) * 50 - 105px) 25px 75px 50px;
  z-index: -1;
}

.side-bar-container{
  display: none;
}

.hero-img{
  grid-column: 1;
  grid-row: 1/span 5;
  box-sizing: border-box;
  border: none;
}

.hero-img-mobile, .hero-img-mobile-LQ{
  background-repeat: no-repeat;
  background-size:cover;
  background-attachment: fixed;
  background-position: top;
}

.hero-img-mobile{
  background-image: url(./assets/images/PortPicMobile.webp);
}

.hero-img-desktop{
  display: none;
}

.herotext {
  grid-row: 2/span 2;
  grid-column: 1;
  backdrop-filter: blur(5px);
  background-color: rgba(255,255,255,.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(40vh - 105px);
  box-sizing: border-box;
  width: 100vw;
  gap: 10px;
  margin: 0;
  padding: 20px;
}

.hero-headline{
  font-size: 1.8em;
  word-spacing: 10px;
  z-index: 1;
  font-weight: 400;
  width: 100vw;
  text-align: center;
  padding: 0 10px;
  margin: 0;
  box-sizing: border-box;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  display: flex;
}

.hero-subheading{
  padding: 0 10px;
  margin: 0;
  font-weight: normal;
  font-size: 1em;
  text-align: center;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  display: flex;
}

.hero-body-text{
  font-size: 0.8em;
  height: -moz-fit-content;
  text-align: center;
  padding: 0 10px;
  margin: 0;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  display: flex;
}

.hero-cta{
  height: 75px;
  width: 75%;
  margin-left: auto;
  border-bottom: none;
  border-right: var(--border);
  border-top: var(--border);
  border-bottom: none;
  border-left: none;
  font-size: 1em;
  font-family:  var(--header-font);
  font-weight: 600;
  color: var(--main-text-color);
  background-color: rgba(255,255,255,.5);
  backdrop-filter: blur(5px);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.hero-cta-wrapper{
  z-index: 1;
  grid-row: 1/span 5;
  grid-column: 1;
  display: flex;
  border-top: var(--border);
  border-right: var(--border);
  align-items: flex-end;
}

.btn-inner-wrapper {
  height: calc(100vh - 160);
  height: calc((var(--vh) * 100) - 160px);
  display: flex;
  width: 80%;
  margin: 0 0 100px 0;
  padding: 0;
  border-bottom: var(--border);
  border-left: var(--border);
  box-sizing: border-box;
  z-index: 2;
  align-items: flex-end;
}

.btn-inner-wrapper div{
  width: 100%;
  font-size: 1.2em;
}

@media screen and (min-width: 425px) {
  .hero-img-mobile, .hero-img-mobile-LQ {
    background-size: contain;
    background-position: center;
    background-color: var(--contrast-text-color);
  }

  .herotext{
    height: 30vh;
  }
}

@media screen and (orientation: landscape) and (min-width: 600px){

  .hero{
    grid-template-columns: 1fr 1fr;
  }

  .herotext {
    grid-column: 1;
    grid-row: 1;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    align-items: flex-start;
    justify-content: space-between;
    backdrop-filter: none;
    background-color: transparent;
    padding: 0;
    margin: 0px 0 0 10%;
  }
  
  .hero-headline{
    padding: 30px 0 0 0;
    width: fit-content;
    height: fit-content;
    text-align: left;
  }
  
  .hero-subheading{
    font-size: 1em;
    text-align: left;
    height: fit-content;
    padding: 0;
  }
  
  .hero-body-text{
    font-size: 0.8em;
    text-align: left;
    height: fit-content;
    padding: 0;
  }
  
  .hero-img{
    grid-column: 2;
    grid-row: 1/span 3;
    border-top: var(--border);
    border-right: var(--border);
    width: 50vw;
  }

  .hero-img-desktop{
    object-fit: cover;
    object-position: top;
    display: block;
  }

  .hero-img-mobile, .hero-img-mobile-LQ{
    display: none;
  }
  
  .hero-cta-wrapper{
    grid-column: 1;
    grid-row: 1/span 3;
    border-left: var(--border);
    border-top: var(--border);
    border-right: none;
    box-sizing: border-box;
  }
  
  .btn-inner-wrapper {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    border-left: 3px solid var(--border);
    margin-left: -3px; 
    margin-bottom: 0;
    font-size: 1.5em;
  } 

  .hero-cta {
    height: 50px;
    width: 88.5%;
    font-size: 0.7em
  }
  
  .btn-inner-wrapper button {
    background: transparent;
    backdrop-filter: none;
    border-bottom: none;
  }
  
  .btn-inner-wrapper div{
    font-size: 1em;
  }
 
}


@media screen and (orientation: landscape) and (min-width: 600px) and (max-height: 500px){
  .hero{
    height:150vh;
    grid-template-rows: 100vh 150vh;
  }

  .hero-cta-wrapper{
    height: calc(150vh - 50px);
  }

  .herotext {
    height: calc(100vh - 100px);
  }
  
  .hero-headline{
    font-size: 3em;
  }
  
  .hero-img{
    height: 150vh;
  }

}

@media screen and (orientation: landscape) and (min-width: 600px) and (min-height: 501px){

  .hero{
    height: calc(100vh - 50px);
    grid-template-rows: 60vh calc(40vh - 50px);
  }

  .herotext {
    height: 40vh;
  }
  
  .hero-cta-wrapper{
    height: calc(100vh - 110px);
  }

  .hero-headline{
    font-size: 2em;
  }

  .hero-img{
    height: calc(100vh - 50px);
  }
}

@media screen and (min-width: 1024px) and (min-height: 501px){

  .hero{
    grid-template-columns: 50px calc(50vw - 50px) auto 50px;
  }

  .hero-img{
    grid-column: 3;
  }

  .hero-img-mobile, .hero-img-mobile-LQ{
    display: none
  }
  
.hero-img-desktop{
  max-height: calc(100vh - 50px);
  height: -webkit-fill-available;
  object-fit: cover;
  width: -webkit-fill-available;
  object-position: top;
  display: block;
}

  .herotext {
    grid-row: 1;
    grid-column: 2;
    margin: 0 0 0 20%;
    height: 60vh;
    backdrop-filter: none;
    padding: 0px;
    width: auto;
    background-color: transparent;
    backdrop-filter: none;
    align-items: flex-start;
  }

  .hero-headline{
    font-size: 4em;
    padding: 50px 0 0 0;
    width: auto;
    text-align: left;
  }

  .hero-subheading{
    font-size: 1.2em;
  }

  .hero-body-text{
    font-size: 0.9em;
  }
  
  .hero-cta-wrapper{
    grid-column: 2;
    margin-bottom: 50px;
    height: auto;
    border-right: none;
  }

  .hero-cta{
    height: 75px;
    width: 75%;
    font-size: 1em;
    background-color: transparent;
    backdrop-filter: none;
  }

.side-bar-container{
  display: block;
  width: 50px;
  height: calc(100vh - 100px);
  position: relative;
  grid-column: 1;
  grid-row: 1;
}

.side-bar {
  display: flex;
  width: 150px;
  height: 50px;
  justify-content: space-between;
  position: relative;
  top: 100%;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  bottom: 0;
  left: 0;
  align-items: center;
}

.side-bar-items{
  margin: 0;
  font-size: 0.75em;
  color: var(--main-text-color);

}

.btn-inner-wrapper{
    height: 100%;
    width: 80%;
    margin: 0 0 0 -3px;
}

}

@media screen and (min-width: 1440px){
  .hero-headline {
    font-size: 5em;
}
}

/*About Section*/

section.about {
  background-color: var(--contrast-bg);
}

.about_inner_wrapper {
  height: fit-content;
  margin: 0;
  border-right: var(--border);
}

.section-title{
  padding: 50px 20px;
  margin: 0;
  font-size: 1.7em;
}

.about_body{
  margin: 0 0 0 20px;
  padding: 0 20px 50px 0;
  font-size: 1.1em;
  font-weight: 400;
  border-bottom: var(--border);
}

.about_header, .about_body{
  color: var(--main-text-color);
}

@media screen and (min-width: 1024px){

  section.about {
    background-color: var(--main-background-color);
  }

.about_inner_wrapper {
  margin: 0 50px 0 0px;
  background-color: var(--contrast-bg);
}

.section-title{
  padding: 100px 50px;
  font-size: 2.5em;
}

.about_body{
  padding: 0 50px 100px 0;
  margin-left: 50px;
}
}

/*Portfolio section*/

section.portfolio{
  background-color: var(--contrast-bg);
}

.portfolio-wrapper{
  padding: 0;
  background-color: var(--main-background-color);
  border-left:  var(--border);
  margin-left: 20px;
}

.portfolio-wrapper .temp-text{
  padding: 0 20px 50px 20px;
  margin: 0;
}

.portfolio-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 25px;
  row-gap: 40px;
  flex-direction: column;
  padding: 0 20px 50px 20px;
}

.portfolio-item {
  height: 50vh;
  width: 100%;
  max-height: 500px;
  flex: 0 1 auto;
  box-sizing: border-box;
  position: relative;
  margin: 0; 
}

.portfolio-item img{
    width: 100%;
    height: 100%;
    max-height: 500px;
    object-fit: cover;
    position: relative;
}

figure{
  margin: 0;
  height: 100%;
  transition: all 0.5s;
}

.figure-shadow{
  box-shadow: var(--box-shadow-mobile);
}

.portfolio-text{
  z-index: 1;
  position: absolute;
  width: 100%;
  text-align: center;
  background-color: #FAF9FA;
  margin: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  opacity: 0.8;
  padding: 0;
  height: 75px;
  bottom: 0;
  transition: all .5s ease;
  font-size: 1.2em;
}

.portfolio-text h3{
  padding: 15px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  font-size: 1em;
}

.View-more__container-inner .portfolio-text h3{
  height: 100%;
}

.View-more__container p{
  margin: 0;
  font-size: 1.1em;
  padding: 0 20px 50px 20px;
}

.portfolio-text p{
  margin: 0 15px;
  font-size: 0.8em;
}

@media screen and (orientation: landscape) and (min-width: 425px) and (max-width: 900px) and (max-height: 500px) {
  .portfolio-item {
    height: 90vh;
    width: calc(50% - 12.5px);
  }

  .portfolio-item-wrapper {
    flex-direction: row;
  }

  .portfolio-text{
    height: 100px;
  }

  .portfolio-text h3{
    height: 100px;
    font-size: 1.3em;
  }

  figure{
    box-shadow: var(--box-shadow-mobile);
  }
}

@media screen and (min-width: 425px) and (max-width: 622px){
  .portfolio-item {
    height: 50vh;
    width: calc(50% - 12.5px);
  }

  .portfolio-item-wrapper {
    flex-direction: row;
  }

  figure{
    box-shadow: var(--box-shadow-mobile);
  }

}

@media screen and (orientation: portrait) and (min-width: 623px){
  .portfolio-item {
    height: 35vw;
    width: 30%;
  }

  .portfolio-item-wrapper {
    flex-direction: row;
    padding: 0 50px 100px 50px;
  }

  .portfolio-text{
    height: 100px;
  }

  .portfolio-text h3{
    height: 100px;
    font-size: 1.3em;
  }

  figure{
    box-shadow: var(--box-shadow-mobile);
  }

}

@media screen and (orientation: landscape) and (min-height: 501px){
  .portfolio-item {
    height: 35vw;
    width: 30%;
  }

  .portfolio-item-wrapper {
    flex-direction: row;
    padding: 0 50px 100px 50px;
  }

  .portfolio-text{
    height: 100px;
  }

  .portfolio-text h3{
    height: 100px;
    font-size: 1.3em;
  }

  figure{
    box-shadow: var(--box-shadow-mobile);
  }

}

@media screen and (min-width: 1024px){

  .View-more__container p{
    margin: 0;
    padding: 0 50px 50px 50px;
  }

  figure{
    box-shadow: var(--box-shadow-desktop);
  }

  .portfolio-wrapper{
    padding: 0;
    margin-left: 50px;
  }

  .portfolio-header{
    margin: 50px 0;
  }

  .portfolio-text{
    font-size: 1em;
  }

  .portfolio-text h3{
    padding: 25px;
    margin: 0;
  }

  .portfolio-text p{
    margin: 0 25px;
    font-size: 1em;
  }
}

/* Contact section */

.contact{
  background: linear-gradient(to right, var(--contrast-bg), var(--main-background-color));
  padding: 0 20px 50px 20px;
  box-sizing: border-box;
}

.contact-text{
  height: fit-content;
  border-top: var(--border);
  border-right: var(--border);
  grid-row: 1;
  color: var(--main-text-color);
  font-weight: 400;
  padding-right: 30px;
}

.contact-text h2 {
  padding-left: 0;
}

.contact-text p {
  margin: 0;
  padding: 0 20px 50px 0;
  font-size: 1.2em;
}

.tel{
  font-weight: bold;
  cursor: pointer;
}

.contact-form{
  border-right: var(--border);
  z-index: 1;
}

form{
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

label{
  color: var(--main-text-color);
  font-size: 1.1em;
  font-weight: 400;
  font-family: var(--body-font);
}

.errorMessage {
  font-size: 1.1em;
  color: var(--glow);
  font-weight: 400;
  font-family: var(--body-font);
}

#fname, #lname, #email {
  height: 50px;
  margin-right: 20px;
  text-indent: 20px;
}

#message{
  height: 200px;
  margin-right: 20px;
  margin-right: 20px;
  padding: 20px;
  box-sizing: border-box;
  border: none;
  resize: none;
}

input {
  border: none;
  background-color: #fff;
}

.submit-btn{
  width: 75%;
  height: 50px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 1em;
  font-family: var(--header-font);
  font-weight: 600;
  color: var(--main-text-color);
  border-top: var(--border);
  box-sizing: border-box;
}

.submit-wrapper{
  text-align: left;
  align-self: flex-end;
  width: 80%;
  margin-top: 25px;
  border-left: var(--border);
  border-bottom: var(--border);
  box-sizing: content-box;
  font-size: 1.2em;
}

.lightbulb-wrapper{
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  right: 0;
}

.lightbulb-wrapper svg{
  stroke: #0C0C4D;
  stroke-width: 3px;
  fill: none;
  display: block;
  height: 100px;
  width: 40px;
  margin-right: 2px;
  z-index: 1;
}

@media (prefers-reduced-motion: no-preference) {
.lightbulb-wrapper-animate{
  animation: glow 4s;
  animation-delay: 1s;
  animation-fill-mode:forwards;
}
}

@keyframes glow {
  0% {filter: none;}
  17% {filter: none;}
  100% {filter: drop-shadow(0px 5px 10px var(--glow));}
}

@media (prefers-reduced-motion: no-preference) {
.outline{
  animation: highlights 4s;
  animation-delay: 1s;
  animation-fill-mode:forwards; 
}
}

@keyframes highlights {
  0% {fill: none;}
  100% {fill: var(--fillament);}
}

.real-outline{
  fill: #0C0C4D
}

@media (prefers-reduced-motion: no-preference) {
.light-inside {
  fill: var(--main-background-color);
  animation: blur 4s;
  animation-delay: 1s;
  animation-fill-mode:forwards;
}
}

@keyframes blur {
  0% {filter: none;}
  17% {filter: none;}
  100% {filter: url(#f1);}
}

@media (prefers-reduced-motion: no-preference) {
.inside-light{
  animation: insidelight 4s;
  animation-delay: 1s;
  animation-fill-mode:forwards;
}
}

@keyframes insidelight {
  0% {fill: var(--main-background-color);}
  17% {fill: var(--main-background-color);}
  100% {fill: var(--lightbulb);}
}

@media (prefers-reduced-motion: no-preference) {
.fillament, .fillament-inside1, .fillament-inside2{
  animation: fillament 3s;
  animation-delay: 1s;
  animation-fill-mode:forwards;
  stroke: var(--fillament);
}
}

@keyframes fillament {
  0% {stroke-width: 0; fill: var(--main-background-color);}
  6% {stroke-width: 70;}
  12% {stroke-width: 0;}
  15% {stroke-width: 40;}
  16% {stroke-width: 0;}
  20% {stroke-width: 50;}
  21% {stroke-width: 0;}
  25% {stroke-width: 60;}
  26% {stroke-width: 0; fill: var(--main-background-color);}
  100% {stroke-width: 150; fill: var(--lightbulb);}
}

.inside-lid6, .inside-lid5, .inside-lid4, .inside-lid3, .inside-lid2, .inside-lid1, .inside-lid {
  fill: lightgray;
}

@media screen and (min-width: 700px){

  .tel{
    font-weight: normal;
    cursor: auto;
  }

  .submit-btn{
    height: 75px;
  }

  .submit-wrapper{
    width: calc(75% - 50px);
  }

  .submit-btn{
    width: 50%;
  }
}

@media screen and (min-width: 1024px){

.contact{
  padding: 0 50px 75px 50px;
}

.contact-text{
  padding-right: 100px;
}

.contact-text p{
  padding: 0 0 50px 0;
  margin: 0;
  font-size: 1.1em;
}

label {
  font-size: 1.1em;
}

#fname, #lname, #email {
  height: 50px;
  margin-right: 50px;
}

#message{
  margin-right: 50px;
}

.submit-wrapper{
  margin-top: 50px;
  font-size: 1.5em;
}

.lightbulb-wrapper svg{
  height: 300px;
  width: 100px;
}

}

/*Portfolio*/

/*Hero section*/

.portfolio__hero-img-mobile, .portfolio__hero-img-desktop{
  width: 100%;
  height: calc(95vh - 70px);
  height: calc((var(--vh) * 95) - 50px);
  object-fit: cover;
  transition: opacity .5s ease;
}

.portfolio__hero-img-desktop{
  display: none;
}

.portfolio__hero-txt{
  position: absolute;
  font-size: 2rem;
  margin: 0;
  background-color: rgba(255,255,255,0.8);
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-family: var(--header-font);
}

.portfolio__hero-container{
  position: relative;
  border-top: var(--border);
  border-right: var(--border);
  height: calc(95vh - 70px);
  height: calc((var(--vh) * 95) - 48px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

@media screen and (min-width: 500px){

  .portfolio__hero-img-mobile{
    display: none;
  }

  .portfolio__hero-img-desktop{
    display: block;
  }
}

@media screen and (min-width: 768px){
  .portfolio__hero-txt{
    font-size: 3rem;
  }

  .portfolio__hero-txt{
    padding: 100px 50px;
  }

}

/*About*/

.Porfolio__about-container{
  background-color: var(--contrast-bg);
}

.Porfolio__about-inner-div{
  border-right: var(--border);
}

/*Responsibilities*/

.Portfolio__resp-container{
  background-color: var(--contrast-bg);
}

.Portfolio__resp-inner{
    margin-left: 20px;
    background-color: var(--main-background-color);
}

.Portfolio__resp-inner-inner{
  margin-right: 20px;
  padding: 0 0 50px 20px;
  border-left: var(--border);
}

.Portfolio__resp-inner-inner .section-title{
  padding-left: 0;
}

.Portfolio__resp-img,.Portfolio__resp-txt{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.hidden-mobile{
 display: none; 
}

.Portfolio__resp-img{
  max-height: 500px;
}

.Portfolio__resp-txt{
  font-size: 1.1em;
}

.Portfolio__resp-item-container{
  display: flex;
  gap: 25px;
  flex-direction: column;
  margin: 25px 0;
  align-items: center;
  box-sizing: border-box;
  padding: 25px;
  transition: all 0.5s ;
}

.Portfolio__resp-item-container-shadow{
  box-shadow: var(--box-shadow-mobile);
}

.Portfolio__resp-item-container:nth-child(2){
  margin-top: 0;
}

.Portfolio__resp-item-container:last-child{
  margin-bottom: 0;
}

.git-link{
  font-weight: bold;
  line-height: 30px;
}

.projects-git-link{
  height: 100px;
  display: flex;
  margin: auto;
  text-align: center;
  font-size: 1em;
  width: 60vw;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  padding: 0;
}

.project-git-wrapper{
  background: linear-gradient(to right, var(--contrast-bg), var(--main-background-color));
}

@media screen and (min-width: 550px){
  .Portfolio__resp-item-container{
    flex-direction: row;
    gap: 50px;
  }

  .Portfolio__resp-item-container:nth-child(odd){
    flex-direction: row-reverse;
  }

  .Portfolio__resp-img, .Portfolio__resp-txt{
    width: 50%;
  }
}

@media screen and (min-width: 800px){
  .hidden-desktop{
    display: none;
  }
  
  .hidden-mobile{
   display: block; 
  }
}

@media screen and (min-width: 1024px){

  .projects-git-link{
    font-size: 1.2em;
  }

  .Portfolio__resp-inner{
    margin-left: 50px;
  }

  .Portfolio__resp-inner-inner{
    margin-right: 50px;
    padding: 0 0 100px 50px;
  }

  .Portfolio__resp-item-container{
    flex-direction: row;
    gap: 50px;
    margin: 50px 0;
  }

  .Portfolio__resp-item-container-shadow{
    box-shadow: var(--box-shadow-desktop);
  }

}

/* View More */

.View-more__container{
  background: linear-gradient(to right, var(--contrast-bg), var(--main-background-color));
  padding-bottom: 25px;
}

.View-more__container-inner{
  display: flex;
  gap: 20px;
  width: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
  margin: 0 20px;
  padding-bottom: 25px;
  height: 50vh;
  min-height: 400px;
  max-height: 500px;
}

.Portfolio__thumbnails {
  width: 80%;
  height: auto;
  object-fit: cover;
  flex: none;
}

.Portfolio__thumbnails .revealOnHover {
  display: none;
}

.revealOnHover{
  opacity: 0;
  display: none;
  -webkit-transition: opacity 0.1s ease 0s;
}

.Portfolio__thumbnails img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (orientation: landscape)  and (max-height: 500px){
  .View-more__container-inner{
    max-height: 90vh;
    height: auto;
    min-height: auto;
  }
  
  .Portfolio__thumbnails {
    max-height: 90vh;
    font-size: 0.8em;
  } 

}

@media screen and (min-width: 600px){
  .Portfolio__thumbnails {
    width: 50%;
  }
}

@media screen and (min-width: 768px){
  
  .Portfolio__thumbnails {
    width: 35%;
  }
}

@media screen and (min-width: 1024px){

  .View-more__container{
    padding-bottom: 50px;
  }

  .View-more__container-inner{
    margin: 0 50px;
    padding-bottom: 50px;
    height: auto;
    min-height: 0;
  }

  .thumbnailLength--6 {
    width: calc((100% - 80px) / 5);
    height: calc((100vw - 180px) / 3.5);
  }

  .thumbnailLength--5 {
    width: calc((100% - 60px) / 4);
  }

  .thumbnailLength--4 {
    width: calc((100% - 40px) / 3);
  }

  .thumbnailLength--3 {
    width: calc((100% - 20px) / 2);
  }

  .thumbnailLength--1 {
    width: 60%;
    margin: auto;
  }
}

/*CTA*/

.portfolio-cta, .git-cta{
  text-align: center;
  height: 75px;
  width: 60vw;
  font-family: var(--header-font);
  font-weight: 600;
  font-size: 1em;
  color: var(--main-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 0 0 auto;
  box-sizing: border-box;
  border-top: none;
}

.portfolio-cta{
  background: none;
  border-right: var(--border);
  border-bottom: var(--border);
  border-left: none;
}

.git-cta{
  border-right: none;
  border-bottom: var(--border);
  border-left: var(--border); 
  background-color: var(--main-background-color);
}

.portfolio-cta__inner-wrapper {
  background-color: var(--contrast-bg);
  margin-right: 20vw; 
  box-sizing: border-box;
}

.portfolio-cta__outer-wrapper {
  background-color: var(--main-background-color);
  box-sizing: border-box;
}

.portfolio-cta__inner-inner{
  margin-left: 20px;
  border-top: var(--border); 
  box-sizing: border-box;
  font-size: 1.2em;
}

@media screen and (min-width: 1024px){


  .portfolio-cta__inner-wrapper {
    margin-right: 25vw;
  }

  .portfolio-cta__inner-inner{
    margin-left: 50px;
    border-top: var(--border);  
    width: calc(75vw - 50px);
    font-size: 1.5em;

  }

  .portfolio-cta, .git-cta{
    height: 100px; 
    font-size: 1em;
    width: 50vw; 
    margin: -3px 0 0 calc(25vw - 50px);
    border-top: var(--border);
  }

  .portfolio-cta{
    background-color: var(--contrast-bg);
  }
}

/*Footer*/

footer{
  display: flex;
  min-height: 100px;
  justify-content: space-between;
  background-color: var(--main-background-color);
  align-items: center;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
  border-top: var(--border);
}

footer .footer-logo, footer .copyright, footer .social, footer .footer-logo-container footer p{
  width: auto;
  margin: 0;
}

.footer-logo{
  font-weight: bold;
}

.footer-logo-container, .footer-nav-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 25px;
}

footer nav{
  width: 300px;
  display: block;
}

footer nav ul{
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

footer .social{
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.social svg{
  width: 40px;
  height: 40px;
}

footer .copyright{
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;
    flex-wrap: wrap;
    justify-content: center;
}

nav ul{
  display: flex;
  justify-content: space-between;;
  padding: 0;
}

nav ul li{
    list-style: none;
    font-family: var(--body-font);
}

@media screen and (min-width: 425px){

  footer nav ul{
    flex-direction: row;
  }
  
}

@media screen and (min-width: 600px){

  footer{
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  .footer-logo-container, .footer-nav-container {
    gap: 50px;
  }
  
}

@media screen and (min-width: 1024px){

  footer{
    flex-wrap: nowrap;
  }

  .footer-logo-container{
    justify-content: flex-start;
    margin-left: 50px;
  }
  
  .footer-nav-container {
    justify-content: flex-end;
    margin-right: 50px;
  }

  .social svg{
    width: 30px;
    height: 30px;
  }
  
}

/*404*/

.header404{
  text-align: center;
  border-top: var(--border);
  box-sizing: border-box;
  margin: 0;
  padding: 50px 0 25px 0;
}

.txt404{
  text-align: center;
  padding: 25px 20px 50px 20px;
  box-sizing: border-box;
  margin: 0;
}

.wrapper404{
  margin: 0 0 50px 0;
  padding: 0;
}